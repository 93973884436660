import React, { useEffect, useState } from "react";

import L from "leaflet";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Polyline,
    ZoomControl,
} from "react-leaflet";

//Hooks
import { usePosition } from "use-position";

//Icons
import UserLocIcon from "./UserLocIcon";
import BusLocIcon from "./BusLocIcon";
import userPosition from "../../assets/user-position.svg";
import bus from "../../assets/bus.svg";
import arret from "../../assets/arret.svg";

//data
import { circuit } from "./data";

//CONSTANTES
const { REACT_APP_TOKEN, REACT_APP_BUS_CODE, REACT_APP_LINE_CODE } =
    process.env;

const arretIcon = new L.Icon({
    iconUrl: arret,
    iconSize: [25, 25],
});
const busIcon = new L.Icon({
    iconUrl: bus,
    iconSize: [50, 50],
    iconAnchor: [25, 50],
});
const userIcon = new L.Icon({
    iconUrl: userPosition,
    iconSize: [40, 40],
});

const pathOptions = { color: "#2B6FB4", weight: 6 };

function Carte({ dimensions }) {
    const [markerLat, setMarkerLat] = useState(0);
    const [markerLong, setMarkerLong] = useState(0);
    const [mapRef, setMapRef] = useState();
    // const [arrets, setArrets] = useState();
    const [navetteOffline, setNavetteOffline] = useState(true);

    //Redimensionne carte si la taile de l'écran change
    useEffect(() => {
        if (mapRef) {
            const timeout = setTimeout(() => {
                console.log("map resize");
                mapRef.invalidateSize();
            }, 100);
            return () => clearTimeout(timeout);
        }
    }, [dimensions]);

    const watch = true;
    const { latitude, longitude, error } = usePosition(watch, {
        enableHighAccuracy: true,
    });

    // useEffect(() => {
    //     getArrets();
    // }, []);

    useEffect(() => {
        getBusPosition();

        if (!navetteOffline) {
            const interval = setInterval(() => {
                getBusPosition();
            }, 5000);
            return () => clearInterval(interval);
        }

        if (navetteOffline) {
            const interval2 = setInterval(() => {
                getBusPosition();
            }, 60000);
            return () => clearInterval(interval2);
        }
    }, [navetteOffline]);

    const getBusPosition = () => {
        fetch(
            `https://navette-biguglia.lagenza.fr/application_navette/webservice/localisation.php?token=${REACT_APP_TOKEN}&bus_code=${REACT_APP_BUS_CODE}`
        )
            .then((res) => res.json())
            .then(
                ({ data, error }) => {
                    if (error === true) {
                        setNavetteOffline(true);
                    } else {
                        setNavetteOffline(false);
                        setMarkerLat(parseFloat(data.latitude));
                        setMarkerLong(parseFloat(data.longitude));
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    // const getArrets = () => {
    //     fetch(
    //         `https://navette-biguglia.lagenza.fr/application_navette/webservice/information.php?line_code=${REACT_APP_LINE_CODE}&bus_code=${REACT_APP_BUS_CODE}`
    //     )
    //         .then((res) => res.json())
    //         .then(
    //             ({ data }) => {
    //                 console.log(data);
    //                 setArrets(data.circuit[0].arret);
    //             },
    //             (error) => {
    //                 console.log(error);
    //             }
    //         );
    // };

    const findUserPosition = () => {
        if (latitude && longitude && !error) {
            mapRef.flyTo([latitude, longitude], 15, { animate: true });
        } else {
            console.log(error);
        }
    };

    const findNavettePosition = () => {
        if (!navetteOffline && markerLat && markerLong) {
            mapRef.flyTo([markerLat, markerLong], 15, { animate: true });
        } else {
            console.log(error);
        }
    };

    return (
        <div>
            <div className="map">
                {navetteOffline && (
                    <p className="alert">
                        La navette n'est pas en service pour le moment
                        <br />
                        <button onClick={() => window.location.reload()}>
                            Actualiser
                        </button>
                    </p>
                )}
                <MapContainer
                    whenCreated={(map) => setMapRef(map)}
                    center={[42.624081, 9.430889]}
                    zoom={15}
                    zoomControl={false}
                >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {/* {arrets &&
                        arrets.map(
                            (
                                { name, latitude, longitude, url_image },
                                index
                            ) => (
                                <Marker
                                    key={index}
                                    position={[latitude, longitude]}
                                    icon={arretIcon}
                                >
                                    <Popup>
                                        <div className="popup">
                                            {url_image && (
                                                <img src={url_image} />
                                            )}
                                            <p className="text-uppercase text-bold">
                                                {name}
                                            </p>
                                        </div>
                                    </Popup>
                                </Marker>
                            )
                        )} */}
                    {!navetteOffline && markerLat && markerLong && (
                        <Marker
                            zIndexOffset={1000}
                            position={[markerLat, markerLong]}
                            icon={busIcon}
                        />
                    )}
                    {latitude && longitude && !error && (
                        <Marker
                            position={[latitude, longitude]}
                            icon={userIcon}
                        />
                    )}
                    <Polyline pathOptions={pathOptions} positions={circuit} />
                    <button
                        onClick={findUserPosition}
                        className="button-location"
                    >
                        <UserLocIcon />
                    </button>
                    {!navetteOffline && (
                        <button
                            onClick={findNavettePosition}
                            className="button-location bus"
                        >
                            <BusLocIcon />
                        </button>
                    )}
                    <ZoomControl position="bottomleft" />
                </MapContainer>
            </div>
        </div>
    );
}

export default Carte;
