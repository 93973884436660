import * as React from 'react';

function BusLocIcon() {
    return (
        <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx={20} cy={20} r={19} fill='#fff' stroke='#2B6FB4' strokeWidth={2} />
            <path
                d='M30.486 18.789l-.217-.073v-2.53a3.163 3.163 0 00-3.181-3.181H9.808C8.795 12.932 8 13.728 8 14.74v10.34h2.892l.072.216c.217.723.94 1.157 1.663 1.157.723 0 1.446-.506 1.663-1.157l.073-.217h10.845l.145.217c.217.723.94 1.157 1.663 1.157.723 0 1.446-.506 1.663-1.157l.072-.217h2.892v-4.627c.072-.796-.434-1.446-1.157-1.663zm-4.555-5.134h1.085c1.374 0 2.53 1.157 2.53 2.53v2.532h-3.615v-5.062zm-5.712 0h5.061v4.99h-5.06v-4.99zm-5.784 0h5.061v4.99h-5.061v-4.99zM8.723 14.74c0-.579.506-1.085 1.085-1.085h3.904v4.99H8.723V14.74zm3.904 11.062a1.112 1.112 0 01-1.084-1.084c0-.579.506-1.085 1.084-1.085.579 0 1.085.506 1.085 1.085 0 .578-.506 1.084-1.085 1.084zm14.389 0a1.112 1.112 0 01-1.085-1.084c0-.579.506-1.085 1.085-1.085.578 0 1.084.506 1.084 1.085 0 .578-.434 1.084-1.084 1.084zm3.976-4.266h-1.446v.723h1.446v2.17h-2.169l-.072-.217c-.217-.724-.94-1.157-1.663-1.157-.723 0-1.446.506-1.663 1.157l-.072.216H14.507l-.072-.216c-.217-.724-.94-1.157-1.663-1.157-.723 0-1.446.506-1.663 1.157l-.217.144H8.723v-2.169h1.446v-.723H8.723v-2.17h21.185c.578 0 1.084.507 1.084 1.085v1.157z'
                fill='#2B6FB4'
                stroke='#2B6FB4'
                strokeWidth={0.5}
                strokeMiterlimit={10}
            />
        </svg>
    );
}

export default BusLocIcon;
