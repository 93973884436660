import * as React from 'react';

function UserLocIcon() {
    return (
        <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx={20} cy={20} r={19.5} fill='#fff' stroke='#2B6FB4' />
            <path
                clipRule='evenodd'
                d='M20 30c5.548 0 10-4.408 10-9.955C30 14.497 25.548 10 20 10s-10 4.497-10 10.045C10 25.592 14.452 30 20 30z'
                stroke='#2B6FB4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='M20 25.833a5.833 5.833 0 100-11.666 5.833 5.833 0 000 11.666z' fill='#2B6FB4' />
            <path d='M6.667 20H10M30 20h3.333M20 6.667V10M20 30v3.333' stroke='#2B6FB4' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
}

export default UserLocIcon;
