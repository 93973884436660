export const circuit = [
    [42.61934, 9.436541],

    [42.61934, 9.436551],

    [42.619451, 9.436556],

    [42.619554, 9.43657],

    [42.619673, 9.43658],

    [42.619749, 9.436584],

    [42.619782, 9.436579],

    [42.619815, 9.436552],

    [42.619847, 9.436489],

    [42.619861, 9.436454],

    [42.619862, 9.436405],

    [42.61986, 9.436367],

    [42.619872, 9.436319],

    [42.620009, 9.436442],

    [42.620086, 9.436489],

    [42.620103, 9.4365],

    [42.620086, 9.436489],

    [42.6201, 9.436444],

    [42.620123, 9.436352],

    [42.620194, 9.435933],

    [42.620232, 9.435686],

    [42.620257, 9.435563],

    [42.620266, 9.435518],

    [42.620319, 9.435263],

    [42.620346, 9.43507],

    [42.620356, 9.434908],

    [42.620354, 9.434678],

    [42.620361, 9.434553],

    [42.6204, 9.434321],

    [42.62045, 9.43401],

    [42.620479, 9.433827],

    [42.62051, 9.433562],

    [42.620534, 9.43331],

    [42.620557, 9.433106],

    [42.620574, 9.432948],

    [42.620585, 9.432881],

    [42.620603, 9.432819],

    [42.620629, 9.432723],

    [42.620701, 9.432535],

    [42.620738, 9.432425],

    [42.620744, 9.432412],

    [42.620761, 9.432367],

    [42.620794, 9.432284],

    [42.620883, 9.432073],

    [42.620982, 9.431853],

    [42.621169, 9.431471],

    [42.621348, 9.431121],

    [42.621452, 9.430872],

    [42.621502, 9.430727],

    [42.621535, 9.430578],

    [42.621596, 9.430322],

    [42.621682, 9.429936],

    [42.621776, 9.429558],

    [42.62185, 9.429261],

    [42.62189, 9.429089],

    [42.621899, 9.429006],

    [42.621919, 9.42885],

    [42.621929, 9.428658],

    [42.62193, 9.428481],

    [42.621927, 9.428286],

    [42.621905, 9.427883],

    [42.621903, 9.427591],

    [42.621907, 9.427471],

    [42.621914, 9.4273],

    [42.621929, 9.427131],

    [42.621948, 9.42699],

    [42.621957, 9.426889],

    [42.622016, 9.426461],

    [42.622019, 9.426412],

    [42.622047, 9.425899],

    [42.622065, 9.425258],

    [42.622066, 9.424811],

    [42.622072, 9.424452],

    [42.622065, 9.424146],

    [42.62206, 9.423978],

    [42.622047, 9.423808],

    [42.621983, 9.423417],

    [42.621913, 9.422981],

    [42.621842, 9.422605],

    [42.621832, 9.422448],

    [42.621839, 9.422399],

    [42.621862, 9.422346],

    [42.621895, 9.422275],

    [42.621962, 9.422188],

    [42.622046, 9.422107],

    [42.622133, 9.422055],

    [42.622213, 9.422028],

    [42.622267, 9.421981],

    [42.622293, 9.421928],

    [42.622314, 9.421813],

    [42.622345, 9.421605],

    [42.622369, 9.421404],

    [42.622395, 9.421156],

    [42.622418, 9.42087],

    [42.622417, 9.42066],

    [42.622409, 9.42058],

    [42.622398, 9.42047],

    [42.622388, 9.420382],

    [42.622392, 9.420333],

    [42.622416, 9.420307],

    [42.62247, 9.420269],

    [42.622653, 9.420188],

    [42.622735, 9.420132],

    [42.622795, 9.420105],

    [42.622848, 9.420107],

    [42.622895, 9.420133],

    [42.622919, 9.420192],

    [42.622974, 9.420306],

    [42.623039, 9.420393],

    [42.62312, 9.420432],

    [42.623218, 9.420449],

    [42.623318, 9.42044],

    [42.623397, 9.420415],

    [42.623426, 9.420439],

    [42.62346, 9.420448],

    [42.623519, 9.420425],

    [42.623578, 9.420374],

    [42.623669, 9.420254],

    [42.623759, 9.42013],

    [42.623818, 9.420089],

    [42.623887, 9.420057],

    [42.623966, 9.420041],

    [42.624006, 9.42004],

    [42.62417, 9.420093],

    [42.624343, 9.420148],

    [42.62447, 9.420169],

    [42.624613, 9.420172],

    [42.624775, 9.420157],

    [42.624886, 9.420166],

    [42.62493, 9.420184],

    [42.624972, 9.420223],

    [42.625042, 9.420394],

    [42.625165, 9.420651],

    [42.625247, 9.420793],

    [42.625295, 9.420859],

    [42.625351, 9.420912],

    [42.625386, 9.420941],

    [42.625418, 9.420947],

    [42.625414, 9.420893],

    [42.625425, 9.420712],

    [42.625433, 9.42058],

    [42.625437, 9.420522],

    [42.625447, 9.420441],

    [42.625481, 9.420384],

    [42.625516, 9.420367],

    [42.625558, 9.42037],

    [42.625603, 9.420389],

    [42.625629, 9.420441],

    [42.625652, 9.420559],

    [42.625671, 9.420729],

    [42.625693, 9.42093],

    [42.625709, 9.421015],

    [42.62573, 9.421076],

    [42.625761, 9.421118],

    [42.6258, 9.42114],

    [42.625857, 9.421166],

    [42.625891, 9.421172],

    [42.625921, 9.421178],

    [42.626002, 9.421183],

    [42.626076, 9.421174],

    [42.626122, 9.421152],

    [42.626185, 9.421096],

    [42.626278, 9.420998],

    [42.626347, 9.420914],

    [42.626431, 9.420833],

    [42.626512, 9.420755],

    [42.626639, 9.420683],

    [42.626785, 9.420618],

    [42.626861, 9.420592],

    [42.626926, 9.42058],

    [42.626933, 9.420623],

    [42.626935, 9.420636],

    [42.626956, 9.42068],

    [42.626999, 9.420732],

    [42.627069, 9.420782],

    [42.62713, 9.420808],

    [42.627183, 9.420831],

    [42.62725, 9.420849],

    [42.627311, 9.420863],

    [42.627341, 9.42088],

    [42.627379, 9.420933],

    [42.627417, 9.421003],

    [42.627455, 9.421116],

    [42.62749, 9.421215],

    [42.62752, 9.421341],

    [42.627545, 9.421431],

    [42.62756, 9.421488],

    [42.627601, 9.421649],

    [42.627633, 9.421723],

    [42.627649, 9.42177],

    [42.627654, 9.421831],

    [42.627645, 9.421953],

    [42.627611, 9.422126],

    [42.627608, 9.422252],

    [42.62761, 9.422355],

    [42.627623, 9.422572],

    [42.627621, 9.422686],

    [42.627616, 9.422782],

    [42.627611, 9.422825],

    [42.627606, 9.422868],

    [42.627598, 9.422894],

    [42.627615, 9.42291],

    [42.627695, 9.422985],

    [42.62778, 9.42306],

    [42.62781, 9.423096],

    [42.627829, 9.423176],

    [42.627833, 9.423243],

    [42.627828, 9.423308],

    [42.627802, 9.423403],

    [42.627765, 9.42346],

    [42.627707, 9.423503],

    [42.627633, 9.423529],

    [42.627575, 9.423529],

    [42.62751, 9.423509],

    [42.627468, 9.423487],

    [42.627422, 9.423466],

    [42.627394, 9.423464],

    [42.627364, 9.42347],

    [42.627337, 9.423495],

    [42.627308, 9.42355],

    [42.627292, 9.423627],

    [42.627294, 9.423693],

    [42.62731, 9.423752],

    [42.627322, 9.42379],

    [42.627327, 9.423808],

    [42.627386, 9.423909],

    [42.627452, 9.423987],

    [42.627528, 9.424041],

    [42.627587, 9.424063],

    [42.627659, 9.424071],

    [42.627762, 9.42404],

    [42.627846, 9.423977],

    [42.627902, 9.423937],

    [42.628023, 9.423824],

    [42.628125, 9.423719],

    [42.628201, 9.423624],

    [42.628244, 9.42358],

    [42.62829, 9.423555],

    [42.628346, 9.423533],

    [42.628426, 9.42352],

    [42.628508, 9.423486],

    [42.628566, 9.423429],

    [42.628652, 9.423294],

    [42.62873, 9.423154],

    [42.628787, 9.423073],

    [42.628861, 9.423],

    [42.628949, 9.422957],

    [42.629008, 9.422952],

    [42.629046, 9.422974],

    [42.62907, 9.423],

    [42.629078, 9.423011],

    [42.629089, 9.423074],

    [42.629083, 9.423139],

    [42.629055, 9.42323],

    [42.62901, 9.423369],

    [42.628946, 9.423576],

    [42.628877, 9.423858],

    [42.628837, 9.424038],

    [42.628797, 9.424235],

    [42.628792, 9.424262],

    [42.628791, 9.424274],

    [42.628779, 9.424373],

    [42.628751, 9.424703],

    [42.628745, 9.424903],

    [42.628745, 9.425137],

    [42.628743, 9.425511],

    [42.628727, 9.425741],

    [42.628694, 9.425932],

    [42.628653, 9.426095],

    [42.62862, 9.426219],

    [42.628548, 9.426466],

    [42.62847, 9.42675],

    [42.628428, 9.426961],

    [42.628419, 9.427049],

    [42.628406, 9.427197],

    [42.628392, 9.427351],

    [42.628395, 9.42744],

    [42.628394, 9.42754],

    [42.628401, 9.427614],

    [42.628417, 9.42777],

    [42.628451, 9.42808],

    [42.628456, 9.428119],

    [42.628451, 9.42808],

    [42.628326, 9.42809],

    [42.628165, 9.428103],

    [42.628086, 9.428102],

    [42.627953, 9.428102],

    [42.627845, 9.4281],

    [42.627747, 9.428117],

    [42.627666, 9.428148],

    [42.627592, 9.428195],

    [42.627542, 9.428254],

    [42.627516, 9.428311],

    [42.627502, 9.428426],

    [42.627503, 9.428487],

    [42.627469, 9.428502],

    [42.627434, 9.428509],

    [42.627287, 9.428525],

    [42.627142, 9.428555],

    [42.62702, 9.428578],

    [42.626845, 9.428611],

    [42.626571, 9.428705],

    [42.626531, 9.428724],

    [42.626287, 9.428841],

    [42.626122, 9.428935],

    [42.625971, 9.429044],

    [42.625898, 9.429108],

    [42.625858, 9.429157],

    [42.625823, 9.429194],

    [42.625796, 9.429169],

    [42.625767, 9.429154],

    [42.625719, 9.429134],

    [42.625453, 9.429002],

    [42.625137, 9.428864],

    [42.624979, 9.4288],

    [42.624951, 9.428794],

    [42.624924, 9.428776],

    [42.624893, 9.428752],

    [42.624855, 9.428721],

    [42.625026, 9.428343],

    [42.625162, 9.42804],

    [42.625085, 9.427971],

    [42.624912, 9.427825],

    [42.624817, 9.427744],

    [42.624714, 9.42765],

    [42.624456, 9.427413],

    [42.624371, 9.427345],

    [42.624313, 9.42733],

    [42.624288, 9.427334],

    [42.624262, 9.427352],

    [42.624233, 9.427406],

    [42.624171, 9.427518],

    [42.62407, 9.427705],

    [42.623919, 9.427975],

    [42.623822, 9.428141],

    [42.623738, 9.428284],

    [42.623659, 9.428388],

    [42.623536, 9.428538],

    [42.623423, 9.428707],

    [42.623363, 9.428821],

    [42.623297, 9.428914],

    [42.623212, 9.429042],

    [42.623087, 9.429255],

    [42.62296, 9.429458],

    [42.622837, 9.429662],

    [42.622714, 9.429863],

    [42.622685, 9.429931],

    [42.622666, 9.430009],

    [42.622659, 9.43006],

    [42.622659, 9.430117],

    [42.622674, 9.430289],

    [42.622728, 9.430688],

    [42.622758, 9.430927],

    [42.622771, 9.431002],

    [42.622797, 9.431071],

    [42.622863, 9.43118],

    [42.622944, 9.431304],

    [42.623062, 9.431493],

    [42.623178, 9.431683],

    [42.623258, 9.431813],

    [42.623383, 9.432026],

    [42.623407, 9.432086],

    [42.623414, 9.432142],

    [42.623408, 9.432235],

    [42.623393, 9.432342],

    [42.623379, 9.432474],

    [42.623367, 9.432603],

    [42.62337, 9.432646],

    [42.623393, 9.432713],

    [42.623427, 9.432777],

    [42.623632, 9.433054],

    [42.623653, 9.433076],

    [42.623752, 9.433176],

    [42.623822, 9.433236],

    [42.623899, 9.433302],

    [42.623948, 9.433348],

    [42.623971, 9.433408],

    [42.623985, 9.43355],

    [42.623982, 9.433674],

    [42.623987, 9.433745],

    [42.624004, 9.433803],

    [42.624034, 9.433892],

    [42.624023, 9.433927],

    [42.624034, 9.433892],

    [42.62407, 9.433815],

    [42.624114, 9.433729],

    [42.624161, 9.433643],

    [42.624233, 9.433548],

    [42.624305, 9.433487],

    [42.624389, 9.433443],

    [42.624467, 9.433418],

    [42.624559, 9.43341],

    [42.624719, 9.43343],

    [42.624799, 9.433431],

    [42.624878, 9.433415],

    [42.624932, 9.433392],

    [42.62498, 9.433353],

    [42.625039, 9.433263],

    [42.625105, 9.433113],

    [42.625201, 9.432869],

    [42.625265, 9.432648],

    [42.625324, 9.432409],

    [42.625367, 9.432276],

    [42.6254, 9.432207],

    [42.625441, 9.43217],

    [42.625499, 9.432147],

    [42.62557, 9.432142],

    [42.625611, 9.432137],

    [42.625617, 9.432181],

    [42.62563, 9.432286],

    [42.625663, 9.432499],

    [42.625687, 9.432666],

    [42.625704, 9.432751],

    [42.625726, 9.43281],

    [42.62579, 9.432889],

    [42.625826, 9.432908],

    [42.625864, 9.432918],

    [42.625899, 9.432917],

    [42.625932, 9.432922],

    [42.626001, 9.432876],

    [42.626059, 9.432813],

    [42.62609, 9.432732],

    [42.626117, 9.43261],

    [42.626133, 9.432475],

    [42.626142, 9.432156],

    [42.626148, 9.432013],

    [42.62615, 9.43192],

    [42.626154, 9.431852],

    [42.626159, 9.431821],

    [42.626169, 9.43179],

    [42.626183, 9.431754],

    [42.626213, 9.431758],

    [42.626252, 9.431755],

    [42.626347, 9.431709],

    [42.626488, 9.431607],

    [42.626628, 9.43151],

    [42.626681, 9.431483],

    [42.62672, 9.431471],

    [42.626746, 9.431433],

    [42.626766, 9.431396],

    [42.626797, 9.431321],

    [42.626871, 9.431122],

    [42.626951, 9.430968],

    [42.62706, 9.430821],

    [42.627175, 9.430712],

    [42.627258, 9.430655],

    [42.627363, 9.430613],

    [42.627419, 9.430595],

    [42.627511, 9.430588],

    [42.627588, 9.430589],

    [42.627688, 9.430608],

    [42.627761, 9.43064],

    [42.627807, 9.430658],

    [42.627856, 9.430688],

    [42.628005, 9.43079],

    [42.628177, 9.43092],

    [42.628336, 9.431071],

    [42.628454, 9.431216],

    [42.628497, 9.431279],

    [42.628517, 9.431294],

    [42.628558, 9.431299],

    [42.628561, 9.431322],

    [42.628592, 9.431587],

    [42.628638, 9.431943],

    [42.628674, 9.432183],

    [42.628702, 9.432323],

    [42.628802, 9.432728],

    [42.628909, 9.433122],

    [42.629019, 9.433403],

    [42.629195, 9.433895],

    [42.629253, 9.43404],

    [42.629346, 9.434285],

    [42.62939, 9.434399],

    [42.6295, 9.434686],

    [42.629624, 9.435009],

    [42.629745, 9.435365],

    [42.629884, 9.435836],

    [42.629996, 9.436243],

    [42.630057, 9.436465],

    [42.630037, 9.436479],

    [42.630024, 9.436502],

    [42.63002, 9.436528],

    [42.630026, 9.436557],

    [42.630042, 9.436578],

    [42.630023, 9.436603],

    [42.629983, 9.436628],

    [42.62989, 9.436654],

    [42.629782, 9.436674],

    [42.629681, 9.436688],

    [42.629559, 9.436702],

    [42.629477, 9.436687],

    [42.629406, 9.436646],

    [42.629318, 9.436581],

    [42.629234, 9.436516],

    [42.629099, 9.436404],

    [42.629006, 9.436343],

    [42.628974, 9.436324],

    [42.628945, 9.436325],

    [42.628905, 9.436334],

    [42.628863, 9.436356],

    [42.628823, 9.436381],

    [42.628797, 9.436449],

    [42.628777, 9.436496],

    [42.628761, 9.436626],

    [42.628734, 9.436879],

    [42.628729, 9.437045],

    [42.628735, 9.437159],

    [42.628739, 9.437238],

    [42.628694, 9.437246],

    [42.628653, 9.43727],

    [42.628607, 9.437316],

    [42.628577, 9.43737],

    [42.628567, 9.437408],

    [42.628559, 9.437452],

    [42.628552, 9.437498],

    [42.628553, 9.43756],

    [42.62857, 9.437616],

    [42.6286, 9.437674],

    [42.628607, 9.437685],

    [42.628631, 9.437721],

    [42.628678, 9.43776],

    [42.628734, 9.437775],

    [42.628745, 9.437816],

    [42.628773, 9.437863],

    [42.62881, 9.437939],

    [42.62884, 9.438037],

    [42.628835, 9.438091],

    [42.628817, 9.438164],

    [42.628779, 9.43825],

    [42.62873, 9.438358],

    [42.628703, 9.438442],

    [42.628695, 9.438491],

    [42.628695, 9.438544],

    [42.628708, 9.438684],

    [42.628755, 9.439076],

    [42.628804, 9.43951],

    [42.628881, 9.440184],

    [42.628897, 9.440506],

    [42.628894, 9.440596],

    [42.628894, 9.440611],

    [42.628885, 9.440713],

    [42.628875, 9.440761],

    [42.62887, 9.440787],

    [42.628858, 9.440836],

    [42.628828, 9.440852],

    [42.628835, 9.440866],

    [42.628828, 9.440852],

    [42.62872, 9.440877],

    [42.62855, 9.440895],

    [42.628359, 9.440909],

    [42.628229, 9.44092],

    [42.628359, 9.440909],

    [42.62855, 9.440895],

    [42.62872, 9.440877],

    [42.628828, 9.440852],

    [42.628858, 9.440836],

    [42.62887, 9.440787],

    [42.628875, 9.440761],

    [42.628885, 9.440713],

    [42.628894, 9.440611],

    [42.628894, 9.440596],

    [42.628897, 9.440506],

    [42.628881, 9.440184],

    [42.628804, 9.43951],

    [42.628755, 9.439076],

    [42.628708, 9.438684],

    [42.628695, 9.438544],

    [42.628695, 9.438491],

    [42.628703, 9.438442],

    [42.62873, 9.438358],

    [42.628779, 9.43825],

    [42.628817, 9.438164],

    [42.628835, 9.438091],

    [42.62884, 9.438037],

    [42.628855, 9.437946],

    [42.628857, 9.437858],

    [42.628866, 9.437782],

    [42.62886, 9.43773],

    [42.628885, 9.437699],

    [42.628908, 9.437667],

    [42.628924, 9.437626],

    [42.628933, 9.437567],

    [42.62894, 9.437498],

    [42.628934, 9.437412],

    [42.628911, 9.437351],

    [42.628877, 9.437297],

    [42.628854, 9.437275],

    [42.628821, 9.437255],

    [42.628782, 9.437241],

    [42.628739, 9.437238],

    [42.628694, 9.437246],

    [42.628653, 9.43727],

    [42.628607, 9.437316],

    [42.628577, 9.43737],

    [42.628529, 9.43737],

    [42.628454, 9.437382],

    [42.628372, 9.437382],

    [42.628063, 9.437356],

    [42.62777, 9.437322],

    [42.627676, 9.437311],

    [42.627302, 9.43726],

    [42.626987, 9.437217],

    [42.626814, 9.437191],

    [42.626592, 9.43716],

    [42.625999, 9.437075],

    [42.625596, 9.43702],

    [42.625333, 9.436982],

    [42.625262, 9.436972],

    [42.625091, 9.436949],

    [42.624635, 9.436879],

    [42.624155, 9.436811],

    [42.623954, 9.436789],

    [42.623739, 9.436771],

    [42.623507, 9.436779],

    [42.623172, 9.436782],

    [42.622769, 9.436825],

    [42.62274, 9.436827],

    [42.622636, 9.43683],

    [42.622531, 9.436829],

    [42.622425, 9.436831],

    [42.622319, 9.436834],

    [42.622216, 9.436839],

    [42.622114, 9.436843],

    [42.622015, 9.436848],

    [42.621916, 9.436853],

    [42.62182, 9.43686],

    [42.621725, 9.436865],

    [42.621631, 9.436872],

    [42.621537, 9.436877],

    [42.621445, 9.436883],

    [42.621357, 9.436889],

    [42.62127, 9.436894],

    [42.621182, 9.436899],

    [42.621096, 9.4369],

    [42.621011, 9.436901],

    [42.620956, 9.4369],

    [42.620929, 9.436899],

    [42.620851, 9.436895],

    [42.620777, 9.436891],

    [42.620707, 9.436882],

    [42.620645, 9.436868],

    [42.620596, 9.436851],

    [42.620547, 9.436835],

    [42.620537, 9.436809],

    [42.620502, 9.436772],

    [42.620472, 9.436753],

    [42.620421, 9.436737],

    [42.620373, 9.436749],

    [42.620342, 9.436764],

    [42.620291, 9.436809],

    [42.620261, 9.436827],

    [42.620211, 9.436838],

    [42.620171, 9.436845],

    [42.620144, 9.43685],

    [42.620066, 9.436856],

    [42.61998, 9.436866],

    [42.619886, 9.43688],

    [42.619784, 9.436899],

    [42.619679, 9.436919],

    [42.619567, 9.436944],

    [42.61945, 9.436966],

    [42.619372, 9.43698],

    [42.619335, 9.436919],

    [42.619323, 9.436837],

    [42.619328, 9.436672],

    [42.619331, 9.436612],

    [42.619338, 9.436551],
];
